import alertify from "alertify.js";
// import getPlatform from "./utils/get-platform";

alertify.logPosition("top right");

export default class NullAnswer {
  constructor({
    nullAnswerElementSelector = null,
    nullValue = null,
    unsubElementSelector = null,
    unsubValue = null,
    formElement = null
  } = {}) {
    // nullValue is required.
    this.nullValue = nullValue;
    if (!this.nullValue) {
      throw new Error(
        "To process a Null Answer, please provide a null answer value."
      );
    }

    if (document.querySelector('div#salsa form[name="actionForm"]') !== null) {
      this.form =
        formElement ||
        document.querySelector('div#salsa form[name="actionForm"]');
      this.crm = "salsa";
    } else if (document.querySelector("form.webform-client-form") !== null) {
      this.form =
        formElement || document.querySelector("form.webform-client-form");
      this.crm = "springboard";
    } else {
      // First available form
      this.form = formElement || document.querySelector("form");
      this.crm = "other";
    }

    if (nullAnswerElementSelector !== null) {
      this.nullAnswerElementSelector = nullAnswerElementSelector;
    } else if (this.form.querySelector('input[type="radio"]') !== null) {
      // Name of first radio button in document.
      this.nullAnswerElementSelector = this.form.querySelector(
        'input[type="radio"]'
      ).name;
    } else {
      // If there's no radio button on the page, the element name must be explicitly declared
      throw new Error(
        "Null Element Name must be explicitly declared for this page."
      );
    }
    if (typeof this.nullAnswerElementSelector !== "string") {
      throw new Error("Null Element Name must be a string.");
    }

    const unsubElements = {
      salsa: { name: "Receive_Email", value: -3 },
      springboard: { name: "submitted[pretreat_opt_out]", value: "TRUE" },
      other: {}
    };

    // TODO: Test if BSD can consume the checkbox even if it's not created in the interface.
    this.unsubElementSelector =
      unsubElementSelector || unsubElements[this.crm].name;

    this.unsubValue = unsubValue || unsubElements[this.crm].value;

    if (
      // check if the element exists on the page
      this.form.querySelector(this.unsubElementSelector) !== null &&
      this.form.querySelector(this.unsubElementSelector).type === "checkbox" &&
      typeof this.unsubValue !== "boolean"
    ) {
      throw new Error(
        "If the Unsub Element is a checkbox, the Unsub Value must be a Boolean."
      );
    }

    // If the unsub element exists, store its original value. In some cases, it will not exist.
    if (this.form.querySelector(this.unsubElementSelector) === null) {
      this.originalUnsubValue = null;
    } else if (
      this.form.querySelector(this.unsubElementSelector).type === "checkbox"
    ) {
      this.originalUnsubValue = document.querySelector(
        this.unsubElementSelector
      ).checked;
    } else {
      this.originalUnsubValue = document.querySelector(
        this.unsubElementSelector
      ).value;
    }
  }

  exists() {
    const nullQuestionInputs = Array.from(
      this.form.querySelectorAll(this.nullAnswerElementSelector)
    );
    return nullQuestionInputs.length
      ? nullQuestionInputs.some(input => {
          if (input.getAttribute("type") === "radio") {
            return input.value.toLowerCase() === this.nullValue.toLowerCase();
          }
          if (input.tagName === "SELECT") {
            const options = Array.from(input.querySelectorAll("option"));
            return options.some(
              option =>
                option.value.toLowerCase() === this.nullValue.toLowerCase()
            );
          }
          return false;
        })
      : false;
  }

  init() {
    if (
      this.exists() // null answer name and value exist in the DOM
    ) {
      // // Remove Salsa AJAX submit handler.
      // if (this.platform.name === "salsa") {
      //   this.platform.form.removeAttribute("onSubmit");
      // }
      this.form.addEventListener("submit", event => {
        this.handleSubmit(event);
      });
    }
  }

  handleSubmit() {
    let userAnswer;
    // Radio buttons
    if (
      this.form.querySelector(
        `${this.nullAnswerElementSelector}[value="${this.nullValue}"]`
      )
    ) {
      userAnswer = this.form.querySelector(
        `${this.nullAnswerElementSelector}:checked`
      )
        ? this.form.querySelector(`${this.nullAnswerElementSelector}:checked`)
            .value
        : null;
    }
    // Select dropdown
    if (
      this.form.querySelector(
        `${this.nullAnswerElementSelector} [value="${this.nullValue}"]`
      )
    ) {
      userAnswer = this.form.querySelector(this.nullAnswerElementSelector)
        .value;
    }
    if (
      userAnswer !== null &&
      userAnswer.toLowerCase() === this.nullValue.toLowerCase()
    ) {
      this.setUnsub();
    } else {
      this.removeUnsub();
    }

    // else if (this.platform.name === "salsa") {
    //   event.preventDefault();
    //   event.stopImmediatePropagation();
    //   if (
    //     document.querySelector('[name="Receive_Email"]') &&
    //     document.querySelector('[name="Receive_Email"]').value === "-3"
    //   ) {
    //     document.querySelector('[name="Receive_Email"]').value = 1;
    //   }
    //   submitForm(this.platform.form);
    // }
  }

  setUnsub() {
    const unsubElement = this.form.querySelector(this.unsubElementSelector);

    if (unsubElement) {
      if (unsubElement.type === "checkbox") {
        unsubElement.checked = this.unsubValue;
      } else {
        unsubElement.value = this.unsubValue;
      }
    } else {
      // Make some regex to create an unsub element
      // https://gist.github.com/Couto/3637328
      const elementRegex = /^(\w+)/i;
      const attributesRegex = /\[([a-z-]+)\s*=\s*("|')*(\S+)("|')*\]/gi;

      const elTagName = elementRegex.exec(this.unsubElementName);
      const elAttributes = attributesRegex.exec(this.unsubElementName);

      const newUnsubElement = document.createFromRegex(elTagName[1]);
      newUnsubElement.setAttribute(elAttributes[1], elAttributes[3]);
      newUnsubElement.setAttribute("type", "hidden");
      newUnsubElement.value = this.unsubValue;
      this.form.appendChild(newUnsubElement);
    }
  }

  removeUnsub() {
    const unsubElement = this.form.querySelector(this.unsubElementSelector);
    if (this.originalUnsubValue) {
      if (unsubElement.type === "checkbox") {
        unsubElement.checked = this.originalUnsubValue;
      } else {
        unsubElement.value = this.originalUnsubValue;
      }
    } else {
      unsubElement.parentElement.removeChild(unsubElement);
    }
  }
}
