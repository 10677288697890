import alertify from "alertify.js";

const spinnerCSS = require("./css/styles.css").toString();

alertify.logPosition("top right");

export default class Verify {
  constructor({
    emailElementSelector = null,
    formElementSelector = null,
    submitElementSelector = null,
    clientName = null
  } = {}) {
    (this.clientName = clientName),
      (this.email =
        document.querySelector(emailElementSelector) ||
        // Salsa
        document.querySelector(
          'div#salsa form[name="actionForm"] input[name="Email"]'
        ) ||
        // Pardot
        document.querySelector("form#pardot-form p.email input") ||
        // Springboard
        document.querySelector(
          "form.webform-client-form input#edit-submitted-take-action-mail"
        ));
    if (!this.email || this.email.nodeName !== "INPUT") {
      throw new Error(
        "To verify an email, please provide the HTML input element that captures email address."
      );
    }
    this.form =
      document.querySelector(formElementSelector) ||
      // Parent of specified email field
      this.email.closest("form") ||
      // Salsa
      document.querySelector('div#salsa form[name="actionForm"]') ||
      // Pardot
      document.querySelector("form#pardot-form") ||
      // Springboard
      document.querySelector("form.webform-client-form");
    if (!this.form || this.form.nodeName !== "FORM") {
      throw new Error("To verify an email, please provide the parent form.");
    }
    this.submit =
      document.querySelector(submitElementSelector) ||
      // BSD, Salsa, Pardot, Springboard, Many custom forms
      this.form.querySelector('input[type="submit"]');
    if (
      !this.submit ||
      !(this.submit.nodeName === "INPUT" || this.submit.nodeName === "BUTTON")
    ) {
      throw new Error(
        "To verify an email, please provide the HTML submit element for the specified form."
      );
    }
  }

  init() {
    this.form.addEventListener("submit", event => {
      this.handleSubmit(event);
    });
  }

  loadingOn() {
    const { submit } = this;
    submit.disabled = true;

    // Create and insert a style block for the loading state.
    const style = document.createElement("style");
    style.type = "text/css";
    style.id = "pretreat-loader-css";
    style.appendChild(document.createTextNode(spinnerCSS));
    document.head.appendChild(style);

    // Create varaibles based on current submit button.
    const { color } = getComputedStyle(submit);
    const height = parseFloat(getComputedStyle(submit).height);
    const marginBottom = parseFloat(getComputedStyle(submit).marginBottom);
    const marginLeft = parseFloat(getComputedStyle(submit).marginLeft);
    const parentMarginBottom = parseFloat(
      getComputedStyle(submit.parentNode).marginBottom
    );
    const width = parseFloat(getComputedStyle(submit).width);

    // Create the spinner element.
    const spinner = document.createElement("div");
    spinner.setAttribute(
      "style",
      `position: relative; top: -${height +
        marginBottom}px; left: ${marginLeft}px; height: 0px; margin-bottom: -${parentMarginBottom}px;`
    );
    const radius = height / 5;
    spinner.innerHTML = `
      <svg width="${width}" height="${height}" id="pretreat-loader">
        <circle 
          id="cCentre" 
          cx="${width / 2}" 
          cy="${height / 2}" 
          r="${radius}" 
          fill="none" 
          stroke="${color}" 
          stroke-width="${height / 10}" 
          stroke-dasharray="${radius * 2 * Math.PI * 0.75}, ${radius *
      2 *
      Math.PI *
      0.25}"
        />
      </svg>
    `;

    // Activate loading CSS on exisitng button.
    submit.classList.add("fluoride-loading");

    // Append spinner element.
    submit.parentNode.appendChild(spinner);
  }

  loadingOff() {
    const { submit } = this;
    const spinner = submit.parentNode.querySelector("#pretreat-loader")
      .parentNode;
    spinner.parentNode.removeChild(spinner);
    submit.classList.remove("fluoride-loading");
    submit.disabled = false;
  }

  verify() {
    let errorMsg;
    if (this.email.value === "") {
      errorMsg = "Please enter a valid email address. (Error code 1000)";
      alertify.error(errorMsg);
      return Promise.reject();
    }
    const body = {
      email: this.email.value,
      recaptchaResponse: null,
      clientName: this.clientName
    };
    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: new Headers({ "Content-Type": "application/json" })
    };
    // Polyfilling fetch API through Webpack integration.
    // Remove the polyfill when the time is right!
    // PRETREAT_VALIDATE_ENDPOINT is filled in by Webpack based on config.
    return fetch(PRETREAT_VALIDATE_ENDPOINT, options)
      .then(response => {
        // response.ok is part of the fetch API. Basically anything that's not a 200 status code.
        if (!response.ok) {
          errorMsg =
            "Something went wrong. Please try again later. (Error code 1001)";
          throw new Error(errorMsg);
        }
        return response.json();
      })
      .then(response => {
        if (
          !(
            response.recaptchaIsValid &&
            response.blacklistIsValid &&
            response.briteverifyIsValid
          )
        ) {
          if (!response.recaptchaIsValid) {
            errorMsg =
              "Something went wrong. Please try again later. (Error code 1002)";
            throw new Error(errorMsg);
          }
          if (!response.blacklistIsValid) {
            errorMsg =
              "The submitted email address includes a term that is not allowed. Please enter a valid email address. (Error code 1003)";
            throw new Error(errorMsg);
          }
          if (response.briteverifyIsValid === false) {
            errorMsg = "Please enter a valid email address. (Error code 1004)";
            throw new Error(errorMsg);
          }
          errorMsg =
            "Something went wrong. Please try again later. (Error code 1005)";
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        // If there's an error fetching a response from our server,
        // resolve the promise as if this is a valid submission.
        // This should be rare.
        alertify.error(error.message);
        throw error;
      });
  }

  handleSubmit(event) {
    if ("checkValidity" in this.form) {
      if (this.form.checkValidity() === false) {
        return;
      }
    }
    if (this.email.value === this.verfiedEmail) {
      // Do nothing. Do not block form submission.
      return Promise.resolve(true);
    }
    // User submitted a new email. Clear the previously verified email address.
    this.verfiedEmail = null;
    event.preventDefault();
    event.stopImmediatePropagation();
    this.loadingOn();
    return this.verify()
      .then(() => {
        this.verfiedEmail = this.email.value;
        this.submit.disabled = false;
        this.submit.click();
        return false;
      })
      .catch(() => {
        this.loadingOff();
        // If the submit is disabled, enable it.
        this.submit.disabled = false;
        // If the submit is hidden, show it.
        this.submit.style.display = "";
        // Remove a Springboard-specific message, but harmless on other platforms.
        this.form.querySelectorAll(".webform-user-thank-you").forEach(el => {
          el.parentNode.removeChild(el);
        });
        return false;
      });
  }
}
